/*
	Only for client customization not for core development cycle.preserve branch changes on merge
    Created on : 12-Feb-2018, 2:24:32 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

.view-registers{
  .cbRegistrationFlat{
    margin-top: 3.5rem;
  }
}
#single-story p,ul li{
  font-family: "Open Sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400;
  color: #000 !important;
  line-height: initial !important;
}

.pvc_results .pvc_header {
  color: #3292d8;
}